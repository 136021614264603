import { OffsiteLink } from '@/components/OffsiteLink';
import { Box, Divider, Group, Stack, Text } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';

interface INews {
  title: string;
  url: string;
}

const NEWS = [
  {
    title: "Restaking Explained: Leveraging Ethereum's Security",
    url: 'https://www.tanssi.network/post/restaking-explained/',
  },
  {
    title:
      'October Highlight: Lumia part of the Tanssi ecosystem and more details about Symbiotic integration',
    url: 'https://www.tanssi.network/post/newsletter-october-2024/',
  },
  {
    title: 'Tanssi Rocks Devcon in Bangkok! 🇹🇭',
    url: 'https://www.tanssi.network/post/tanssi-devcon-2024/',
  },
] satisfies INews[];

export function News() {
  return (
    <Stack mt={'md'} gap={0}>
      {NEWS.map(({ title, url }, i) => (
        <Box key={url}>
          <OffsiteLink
            url={url}
            isWrapper
            withIcon={false}
            td={'none'}
            w={'100%'}
          >
            <Group w={'100%'} justify="space-between" align="end" wrap="nowrap">
              <Text size="md">{title}</Text>
              <Box miw={20}>
                <IconArrowRight size={16} color="white" />
              </Box>
            </Group>
          </OffsiteLink>
          {i !== NEWS.length - 1 && <Divider my={'xs'} color="dark.6" />}
        </Box>
      ))}
    </Stack>
  );
}
