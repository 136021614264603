import { Status } from '@/components/AppchainCard/Status';
import { AppchainIdenticon } from '@/components/AppchainIdenticon';
import { Badge } from '@/components/Badge';
import { useChainConfig } from '@/hooks/useChainConfig';
import { ChainKind, useChainKind } from '@/hooks/useChainKind';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import type { PublicAppchain } from '@/shared/db/entities/appchains';
import {
  Anchor,
  type AnchorProps,
  Box,
  Group,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useHover, useSessionStorage } from '@mantine/hooks';
import Link from 'next/link';
import { FaAngleRight } from 'react-icons/fa6';

interface Props extends AnchorProps {
  appchain: PublicAppchain;
  href: string;
  withStatus?: boolean;
  withHover?: boolean;
}

export function AppchainRow({
  appchain: {
    chainKey,
    paraId,
    logo,
    name,
    symbol,
    isEvm,
    evmChainId,
    runtime,
  },
  withStatus = false,
  withHover = true,
  href,
  ...others
}: Props) {
  const theme = useMantineTheme();
  const { config } = useChainConfig(chainKey);
  const { ref, hovered } = useHover<HTMLAnchorElement>();
  const { xs, sm, lg } = useMediaQuery();
  const chainKind = useChainKind(chainKey);

  const [, setFromNetworks] = useSessionStorage({
    key: 'from-networks',
    defaultValue: false,
  });

  return (
    <Anchor
      component={Link}
      ref={ref}
      href={href}
      onClick={() => setFromNetworks(true)}
      py={'xs'}
      px={{ base: 'xs', sm: 'md' }}
      c={'inherit'}
      miw={'100%'}
      td={'none'}
      bg={
        hovered && withHover
          ? theme.colors.dark[7]
          : others.bg || theme.colors.dark[8]
      }
      style={{ borderRadius: 10 }}
      {...others}
    >
      <Group justify="space-between">
        <Group flex={1} gap={xs ? 'md' : 'xs'}>
          <AppchainIdenticon logoUrl={logo} paraId={paraId} size={40} />
          <Group flex={1} gap={'xs'}>
            <Group
              flex={1}
              align="flex-start"
              gap={0}
              style={{
                flexDirection:
                  chainKind === ChainKind.Mainnet ? 'row' : 'column',
              }}
            >
              <Group gap={'xs'}>
                <Text>{name || paraId}</Text>
                {symbol && <Text c={'gray.6'}>{symbol.toUpperCase()}</Text>}
                <Box ml={5}>
                  {withStatus && <Status paraId={paraId} config={config} />}
                </Box>
              </Group>
              <Group mt={5} gap={'xs'}>
                {chainKind === ChainKind.Snap && (
                  <Badge variant={'teal'} py={12} px={8}>
                    trial
                  </Badge>
                )}
                {chainKind === ChainKind.Dedicated && (
                  <Badge variant={'orange'} py={12} px={8}>
                    dedicated
                  </Badge>
                )}
                {isEvm ? (
                  <Badge variant={'yellow'} py={12} px={8}>
                    evm
                  </Badge>
                ) : (
                  <Badge variant={'blue'} py={12} px={8}>
                    substrate
                  </Badge>
                )}
              </Group>
            </Group>
            {sm && (
              <Group w={'60%'} gap={'lg'}>
                <Group gap={5}>
                  <Text c={theme.other.colors.graphiteGray}>Network ID:</Text>
                  <Text>{paraId}</Text>
                </Group>
                {runtime && lg && (
                  <Group gap={5}>
                    <Text c={theme.other.colors.graphiteGray}>Runtime:</Text>
                    <Text>{runtime}</Text>
                  </Group>
                )}
                {evmChainId && (
                  <Group gap={5}>
                    <Text c={theme.other.colors.graphiteGray}>EVM ID:</Text>
                    <Text>{evmChainId}</Text>
                  </Group>
                )}
              </Group>
            )}
          </Group>
        </Group>
        {xs && <FaAngleRight fontSize={20} />}
      </Group>
    </Anchor>
  );
}
