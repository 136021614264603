import { OffsiteLink } from '@/components/OffsiteLink';
import { Anchor, Group, Title } from '@mantine/core';
import Image from 'next/image';
import Link from 'next/link';

interface BuilderResource {
  name: string;
  url: string;
  image: string;
}

const RESOURCES = [
  {
    name: 'Developer Documentation',
    url: 'https://docs.tanssi.network/',
    image: '/images/dev-docs.webp',
  },
  {
    name: 'Getting Started',
    url: 'https://docs.tanssi.network/builders/',
    image: '/images/getting-started.webp',
  },
  {
    name: 'Try the Demo Network',
    url: '/demo',
    image: '/images/demo-network.webp',
  },
  {
    name: 'Get Dev Support',
    url: 'https://discord.gg/tw7muAspeW',
    image: '/images/dev-support.webp',
  },
  {
    name: 'Explore Tanssi- Powered Networks',
    url: '/networks',
    image: '/images/networks.webp',
  },
  {
    name: 'Subscribe to Newsletter',
    url: 'https://www.tanssi.network/newsletter',
    image: '/images/newsletter.webp',
  },
] satisfies BuilderResource[];

export function BuildersResources() {
  const ResourceCard = ({ name, image }: { name: string; image: string }) => (
    <Group
      p={'sm'}
      bg={'dark.7'}
      miw={300}
      mih={78}
      flex={1}
      justify="space-between"
      style={{
        border: '1px solid var(--mantine-color-dark-6)',
        borderRadius: 10,
      }}
    >
      <Group gap={'md'}>
        <Image src={image} alt={name} width={48} height={48} />
        <Title
          order={4}
          size={18}
          c={'white'}
          style={{
            fontFamily: 'Epilogue Extra Bold, sans-serif',
          }}
          w={184}
        >
          {name}
        </Title>
      </Group>
      <Image
        src="/images/up-right-arrow.svg"
        alt="arrow-right"
        width={10}
        height={10}
      />
    </Group>
  );

  return (
    <Group mt={'md'} gap={'sm'}>
      {RESOURCES.map(({ name, image, url }) =>
        url.startsWith('http') ? (
          <OffsiteLink
            key={name}
            url={url}
            isWrapper
            withIcon={false}
            td={'none'}
            flex={1}
          >
            <ResourceCard name={name} image={image} />
          </OffsiteLink>
        ) : (
          <Anchor component={Link} key={name} href={url} td={'none'} flex={1}>
            <ResourceCard name={name} image={image} />
          </Anchor>
        ),
      )}
    </Group>
  );
}
