import { AppchainRow } from '@/components/AppchainRow';
import { Card } from '@/components/Card';
import { MainTitle } from '@/components/MainTitle';
import { BuildersResources } from '@/components/pages/Home/BuildersResources';
import { News } from '@/components/pages/Home/News';
import { PortfolioHome } from '@/components/pages/Home/StakingPortfolio';
import { dancebox } from '@/config';
import { useUserAppchains } from '@/hooks/api/useUserAppchains';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Box, Stack } from '@mantine/core';
import {
  Anchor,
  Button,
  Group,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import Image from 'next/image';
import Link from 'next/link';

export function Home() {
  const theme = useMantineTheme();
  const { xs, sm, lg } = useMediaQuery();
  const { appchains: userAppchains } = useUserAppchains();

  return (
    <>
      <MainTitle>Welcome to Tanssi's Testnet</MainTitle>
      <Text>
        {`Launch decentralized networks with full customization and security.${xs ? " Experience the power of Tanssi's testnet before mainnet goes live." : ''}`}
      </Text>

      {!!userAppchains?.length && (
        <Card mt={'lg'}>
          <Group justify="space-between">
            <Title
              order={2}
              size={24}
              fw={800}
              c={'white'}
              style={{
                fontFamily: 'Epilogue Extra Bold, sans-serif',
              }}
            >
              Launched Networks
            </Title>
            {xs && (
              <Anchor
                variant={'transparent'}
                component={Link}
                href={'/dashboard'}
              >
                <Group gap={5}>
                  <Text>Manage Networks</Text>
                  <IconArrowRight size={15} color="white" />
                </Group>
              </Anchor>
            )}
          </Group>
          <Group mt={'md'} w={'100%'}>
            {userAppchains.map((appchain) => (
              <AppchainRow
                href="/dashboard"
                key={`${appchain.chainKey}-${appchain.paraId}`}
                appchain={appchain}
                bg={theme.colors.dark[9]}
                withStatus
                withHover={false}
              />
            ))}
          </Group>
        </Card>
      )}

      <Group
        align="stretch"
        gap={'lg'}
        style={{ flexDirection: sm ? 'row' : 'column' }}
      >
        <Card mt={'lg'} variant="orange" h={'auto'} flex={1}>
          <Image
            src="/images/launch_network.webp"
            alt="Launch network image"
            width={lg ? 160 : 150}
            height={lg ? 160 : 150}
            style={{
              position: 'absolute',
              top: '50%',
              left: lg ? '62%' : '55%',
              transform: 'translateY(-50%)',
              opacity: lg ? 1 : 0.2,
            }}
          />
          <Stack
            w={{ base: '80%', lg: '60%' }}
            gap={'xs'}
            justify="space-between"
            h={'100%'}
          >
            <Box>
              <Title
                order={2}
                size={24}
                fw={800}
                c={'white'}
                style={{
                  fontFamily: 'Epilogue Extra Bold, sans-serif',
                }}
              >
                Launch Network
              </Title>
              <Text size="sm" mt={'xs'}>
                Launch a network secured by Symbiotic's Ethereum restaking with
                full customization options.
              </Text>
            </Box>
            <Button
              component={Link}
              href="/create"
              mt={'xs'}
              color={theme.other.colors.orange}
              w={170}
            >
              Start Building
            </Button>
          </Stack>
        </Card>

        <Card mt={{ base: 0, sm: 'lg' }} variant="teal" h={'auto'} flex={1}>
          <Image
            src="/images/browse_networks.webp"
            alt="All networks image"
            objectFit={'contain'}
            width={lg ? 205 : 190}
            height={lg ? 160 : 150}
            style={{
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
              opacity: lg ? 1 : 0.2,
            }}
          />
          <Stack
            w={{ base: '80%', lg: '60%' }}
            gap={'xs'}
            justify="space-between"
            h={'100%'}
          >
            <Box>
              <Title
                order={2}
                size={24}
                fw={800}
                c={'white'}
                style={{
                  fontFamily: 'Epilogue Extra Bold, sans-serif',
                }}
              >
                Explore Networks
              </Title>
              <Text size="sm" mt={'xs'}>
                Dive into all the Tanssi-powered networks and see what's being
                built with Tanssi.
              </Text>
            </Box>

            <Button component={Link} href="/networks" mt={'xs'} w={170}>
              Browse Networks
            </Button>
          </Stack>
        </Card>
      </Group>

      <Card mt={'lg'}>
        <Group
          justify="space-between"
          gap={'xl'}
          style={{ flexDirection: sm ? 'row' : 'column' }}
        >
          <Stack gap={'sm'} justify="start" flex={1}>
            <Title
              order={3}
              size={24}
              fw={800}
              c={'white'}
              style={{
                fontFamily: 'Epilogue Extra Bold, sans-serif',
              }}
            >
              Staking
            </Title>
            <Text>
              Get your hands on the staking experience before mainnet. Start
              supporting the Tanssi ecosystem and explore the process firsthand.
            </Text>
            {sm && (
              <Button component={Link} href="/staking" mt={'sm'} w={170}>
                Stake
              </Button>
            )}
          </Stack>
          <Group justify="center" ml={{ base: 0, sm: 'md' }}>
            {/* TODO: once dancelight is added, we’ll need to display the total balance of both networks in this component  */}
            <PortfolioHome config={dancebox} />
          </Group>
          {!sm && (
            <Group justify="start" w={'100%'}>
              <Button
                component={Link}
                href="/staking"
                w={{ base: '100%', xs: 170 }}
              >
                Stake
              </Button>
            </Group>
          )}
        </Group>
      </Card>

      <Group
        mt={'xl'}
        align="stretch"
        gap={'lg'}
        style={{ flexDirection: sm ? 'row' : 'column' }}
      >
        <Box w={{ base: '100%', lg: '60%' }}>
          <Title
            order={3}
            size={20}
            fw={800}
            c={theme.other.colors.yellow}
            style={{
              fontFamily: 'Epilogue Extra Bold, sans-serif',
            }}
          >
            Builder's Resources
          </Title>
          <BuildersResources />
        </Box>

        <Stack flex={1} justify="space-between" h="auto">
          <Box>
            <Title
              order={3}
              size={20}
              fw={800}
              c={theme.other.colors.lightGreen}
            >
              Tanssi Insights & Updates
            </Title>
            <News />
          </Box>
          <Box ta={'right'}>
            <Anchor
              href="https://www.tanssi.network/resources/categories/announcements"
              c={'tanssiTeal.9'}
            >
              Explore more
            </Anchor>
          </Box>
        </Stack>
      </Group>
    </>
  );
}
