import { ChainKey } from '@/config';
import { useMemo } from 'react';

export enum ChainKind {
  Snap = 'Trial',
  Dedicated = 'Dedicated',
  Mainnet = 'Mainnet',
}

export function useChainKind(chainKey: ChainKey): ChainKind {
  return useMemo(() => {
    if (
      [
        ChainKey.Local,
        ChainKey.Stagebox,
        ChainKey.Stagelight,
        ChainKey.Dancebox,
      ].includes(chainKey)
    ) {
      return ChainKind.Dedicated;
    }

    if (ChainKey.Flashbox === chainKey) {
      return ChainKind.Snap;
    }

    return ChainKind.Mainnet;
  }, [chainKey]);
}
