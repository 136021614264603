import {
  Portfolio,
  PortfolioBalance,
} from '@/components/pages/Staking/Portfolio';
import type { ChainConfigProps } from '@/config';
import { usePortfolio } from '@/hooks/polkadot/staking';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';

interface Props extends ChainConfigProps {}

export function PortfolioHome({ config }: Props) {
  const theme = useMantineTheme();
  const { xs } = useMediaQuery();

  const {
    totalAssetAmount,
    freeAssetAmount,
    manualStakeAssetAmount,
    compoundingStakeAssetAmount,
    manualRewardsAssetAmount,
    compoundingRewardsAssetAmount,
  } = usePortfolio(config);

  const balances = useMemo(
    () => [
      {
        name: PortfolioBalance.Free,
        value: freeAssetAmount,
        color: theme.white,
      },
      {
        name: PortfolioBalance.TotalStaked,
        value: config.getAssetAmount(
          (manualStakeAssetAmount?.amount || 0n) +
            (compoundingStakeAssetAmount?.amount || 0n),
        ),
        color: theme.other.colors.lightGreen,
      },
      {
        name: PortfolioBalance.TotalRewards,
        value: config.getAssetAmount(
          (manualRewardsAssetAmount?.amount || 0n) +
            (compoundingRewardsAssetAmount?.amount || 0n),
        ),
        color: theme.other.colors.yellow,
      },
    ],
    [
      freeAssetAmount,
      manualStakeAssetAmount,
      compoundingStakeAssetAmount,
      manualRewardsAssetAmount,
      compoundingRewardsAssetAmount,
      config,
      theme,
    ],
  );

  return (
    <Portfolio
      balances={balances}
      totalAssetAmount={totalAssetAmount}
      chartSize={xs ? 160 : 220}
      chartThickness={xs ? 16 : 24}
    />
  );
}
